import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { login } from "./services/cognitoService";
import ConfirmAccount from "./ConfirmAccount";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import "./Login.css";

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [showConfirmAccount, setShowConfirmAccount] = useState(false);
  
  const navigate = useNavigate(); // Initialize navigate

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const result = await login(email, password);
      
      if (result.unconfirmed) {
        setShowConfirmAccount(true);
      } else {
        onLogin(result.session);
        setMessage("Login successful!");
        console.log("Redirecting to landing page...");
        navigate("/landing"); // Make sure this matches the route in App.js
      }
    } catch (error) {
      setMessage(error.message || "Login failed.");
    }
  };

  if (showConfirmAccount) {
    return <ConfirmAccount email={email} />;
  }

  return (
    <div>
      <Header /> {/* Render the Header component */}
      
      <div className="login-page">
        <div className="login-container">
          <h2>Login</h2>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="login-input"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="login-input"
            />
            <button type="submit" className="login-button">Log In</button>
            <p className="error-message">{message}</p>
          </form>
          <div className="login-links">
            <a href="https://www.britishtriathlon.org/my-account/forgotten-password">
              Forgotten Password?
            </a>
            <Link to="/signup">Don’t have an account? Register here</Link> {/* Link to internal Signup page */}
          </div>
        </div>
      </div>

      <Footer /> {/* Render the Footer component */}
    </div>
  );
};

export default Login;
