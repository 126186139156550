import React from "react";
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        
        {/* Triathlon Regions Section */}
        <div className="footer-section">
          <h4>TRIATHLON ENGLAND</h4>
          <p>
            <a href="https://www.britishtriathlon.org/england">Join</a> | 
            <a href="https://www.britishtriathlon.org/england/events">Events</a> | 
            <a href="https://www.britishtriathlon.org/england/clubs">Clubs</a>
          </p>
          
          <h4>WELSH TRIATHLON</h4>
          <p>
            <a href="https://www.britishtriathlon.org/wales">Join</a> | 
            <a href="https://www.britishtriathlon.org/wales/events">Events</a> | 
            <a href="https://www.britishtriathlon.org/wales/clubs">Clubs</a>
          </p>

          <h4>TRIATHLON SCOTLAND</h4>
          <p>
            <a href="https://www.britishtriathlon.org/scotland">Join</a> | 
            <a href="https://www.britishtriathlon.org/scotland/events">Events</a> | 
            <a href="https://www.britishtriathlon.org/scotland/clubs">Clubs</a>
          </p>
        </div>

        {/* Useful Links Section */}
        <div className="footer-section">
          <h4>USEFUL LINKS</h4>
          <p><a href="https://www.britishtriathlon.org/about-us">About Us</a> »</p>
          <p><a href="https://www.britishtriathlon.org/events">Events</a> »</p>
          <p><a href="https://www.britishtriathlon.org/safeguarding">Safeguarding</a> »</p>
          <p><a href="https://www.britishtriathlon.org/news">News</a> »</p>
          <p><a href="https://www.britishtriathlon.org/clubs">Clubs</a> »</p>
          <p><a href="https://www.britishtriathlon.org/job-vacancies">Job Vacancies</a> »</p>
          <p><a href="https://www.britishtriathlon.org/triathlon-trust">Triathlon Trust</a> »</p>
          <p><a href="https://www.britishtriathlon.org/media">Media</a> »</p>
          <p><a href="https://www.britishtriathlon.org/gb-teams">GB Teams</a> »</p>
          <p><a href="https://www.britishtriathlon.org/partners">Partners</a> »</p>
          <p><a href="https://www.britishtriathlon.org/privacy-policy">Privacy Notice</a> »</p>
        </div>

        {/* Contact Us Section */}
        <div className="footer-section">
          <h4>CONTACT US</h4>
          <img 
            src="https://www.britishtriathlon.org/britain/images/logos/british-triathlon-logo.svg" 
            alt="British Triathlon Logo" 
            className="footer-logo" 
            style={{ width: "100px", marginBottom: "1rem" }}
          />
          <p>© 2024 British Triathlon<br />
             Michael Pearson East,<br />
             British Triathlon Federation,<br />
             1 Oakwood Drive,<br />
             Loughborough, Leicestershire, England,<br />
             LE11 3QF
          </p>
          <a className="contact-button" href="https://www.britishtriathlon.org/contact-us">GET IN TOUCH</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
