// src/config.js
export const cognitoConfig = {
    region: "eu-west-2",            
    UserPoolId: "eu-west-2_dTMZXmmgH", 
    ClientId: "4i1mvtgjro3o2acn42h6q0iovc",  
  };
  
  export const apiConfig = {
    PeopleBaseUrl: "https://rueqmbyae7.execute-api.eu-west-2.amazonaws.com/prod",
    signupBaseURL: "https://i14m0zu1t1.execute-api.eu-west-2.amazonaws.com/prod"
    // Actual API Gateway ID
  };
  