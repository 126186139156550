// src/App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Login from "./Login";
import Signup from "./Signup";
import ConfirmAccount from "./ConfirmAccount";
import LandingPage from "./LandingPage";

function App() {
  const [session, setSession] = useState(null);
  const [isSignedUp, setIsSignedUp] = useState(false);
  const [email, setEmail] = useState("");

  const handleLogin = (session) => {
    setSession(session);
    setEmail("");
  };

  const handleLogout = () => {
    setSession(null);
  };

  const handleSignUp = (email) => {
    setIsSignedUp(true);
    setEmail(email);
  };

  return (
    <Router>
      <Routes>
        {/* Default route redirects to Login or LandingPage based on session */}
        <Route path="/" element={session ? <Navigate to="/landing" /> : <Navigate to="/login" />} />

        {/* Route for Login */}
        <Route path="/login" element={<Login onLogin={handleLogin} />} />

        {/* Route for Signup */}
        <Route path="/signup" element={<Signup onSignUp={handleSignUp} />} />

        {/* Route for ConfirmAccount */}
        <Route
          path="/confirm-account"
          element={
            isSignedUp ? (
              <ConfirmAccount email={email} />
            ) : (
              <Navigate to="/signup" />
            )
          }
        />

        {/* Route for LandingPage */}
        <Route
          path="/landing"
          element={
            session ? (
              <LandingPage token={session.getIdToken().getJwtToken()} onLogout={handleLogout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* Redirect any unknown route to Login */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
}

export default App;
