import React from "react";
import "./Header.css";

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
        {/* Logo */}
        <img 
          src="https://www.britishtriathlon.org/britain/images/logos/british-triathlon-logo.svg" 
          alt="British Triathlon Logo" 
          className="header-logo"
        />
        
        {/* Navigation Links */}
        <nav className="header-nav">
          <a href="https://www.britishtriathlon.org/get-involved">Get Involved</a>
          <a href="https://www.britishtriathlon.org/events">Events</a>
          <a href="https://www.britishtriathlon.org/shop">Shop</a>
          <a href="https://www.britishtriathlon.org/tips-and-advice">Tips and Advice</a>
          <a href="https://www.britishtriathlon.org/news">News</a>
          <a href="https://www.britishtriathlon.org/clubs">Clubs</a>
          <a href="https://www.britishtriathlon.org/gb-teams">GB Teams</a>
          <a href="https://www.britishtriathlon.org/about-us">About Us</a>
          <a href="https://www.britishtriathlon.org/my-account">My Account</a>
          <a href="https://www.britishtriathlon.org/membership" className="membership-button">Membership</a>
        </nav>
      </div>
      
      {/* My Account Title */}
      <div className="my-account-title">
        <h1>MY ACCOUNT</h1>
      </div>
    </header>
  );
};

export default Header;
