import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiConfig } from "./config";
import { logout } from "./services/cognitoService";

const LandingPage = ({ token, onLogout }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        // Fetch user details from the database
        const response = await axios.get(`${apiConfig.PeopleBaseUrl}/people`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUserDetails(response.data); // If user exists, set user details
        console.log("User data fetched successfully:", response.data);

      } catch (error) {
        console.log("Error fetching user data:", error); // Log error for debugging

        // Check if the error status is 404, indicating the user does not exist
        if (error.response && error.response.status === 404) {
          console.log("User not found, attempting to sign up...");

          try {
            // Call /signup to create the user
            await axios.post(
              `${apiConfig.signupBaseURL}/signup`,
              {}, // Pass user details if necessary
              {
                headers: { Authorization: `Bearer ${token}` },
              }
            );

            // After successful signup, call /people again to load the user data
            const responseAfterSignup = await axios.get(`${apiConfig.PeopleBaseUrl}/people`, {
              headers: { Authorization: `Bearer ${token}` },
            });
            setUserDetails(responseAfterSignup.data); // Set the fetched user details
            setMessage("User created and details loaded successfully.");
            console.log("User created and data loaded:", responseAfterSignup.data);

          } catch (signupError) {
            setMessage("Failed to create user.");
            console.log("Error during signup:", signupError);
          }

        } else {
          // Handle other errors that aren't 404
          setMessage("Failed to fetch user details.");
          console.log("Unexpected error fetching user details:", error);
        }
      }
    };

    fetchUserDetails();
  }, [token]);

  const handleLogout = () => {
    logout(); // Clear the Cognito session
    onLogout(); // Notify parent to clear the session state in App.js
  };

  return (
    <div>
      <h2>Welcome to Your Profile</h2>
      <button onClick={handleLogout}>Log Out</button> {/* Logout button */}

      {message && <p>{message}</p>}

      {userDetails ? (
        <div>
          <h3>Your Details</h3>
          <p><strong>First Name:</strong> {userDetails.first_name}</p>
          <p><strong>Last Name:</strong> {userDetails.last_name}</p>
          <p><strong>Email:</strong> {userDetails.email}</p>
          <p><strong>Date of Birth:</strong> {userDetails.date_of_birth}</p>
          <p><strong>Gender:</strong> {userDetails.gender}</p>
          <p><strong>Primary Phone:</strong> {userDetails.primary_phone}</p>
          <p><strong>Secondary Phone:</strong> {userDetails.secondary_phone}</p>
        </div>
      ) : (
        <p>Loading user details...</p>
      )}
    </div>
  );
};

export default LandingPage;
