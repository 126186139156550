// src/services/cognitoService.js
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from "amazon-cognito-identity-js";
import { cognitoConfig } from "../config";

// Initialize Cognito User Pool
const userPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.UserPoolId,
  ClientId: cognitoConfig.ClientId,
});

// Define the signUp function with standard attributes
export const signUp = (email, password, additionalAttributes) =>
  new Promise((resolve, reject) => {
    const attributeList = [
      new CognitoUserAttribute({ Name: "email", Value: email }),
      new CognitoUserAttribute({ Name: "birthdate", Value: additionalAttributes.birthdate }), // Standard attribute
      new CognitoUserAttribute({ Name: "given_name", Value: additionalAttributes.first_name }), // Standard attribute
      new CognitoUserAttribute({ Name: "family_name", Value: additionalAttributes.last_name }), // Standard attribute
      new CognitoUserAttribute({ Name: "gender", Value: additionalAttributes.gender }), // Standard attribute
    ];

    userPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        console.error("Signup failed:", err.message); // Log error for debugging
        return reject(err);
      }
      resolve(result.user);
    });
  });

// Define the login function with unconfirmed user detection
export const login = (email, password) =>
  new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (session) => resolve({ session }),
      onFailure: (err) => {
        if (err.code === 'UserNotConfirmedException') {
          resolve({ unconfirmed: true });
        } else {
          console.error("Login failed:", err.message);
          reject(err);
        }
      },
    });
  });

// Define the confirmSignUp function for user confirmation
export const confirmSignUp = (email, code) =>
  new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.confirmRegistration(code, true, (err, result) => {
      if (err) {
        console.error("Confirmation failed:", err.message);
        return reject(err);
      }
      resolve(result);
    });
  });

// Define the resendConfirmationCode function to send a new code
export const resendConfirmationCode = (email) =>
  new Promise((resolve, reject) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.resendConfirmationCode((err, result) => {
      if (err) {
        console.error("Resend code failed:", err.message);
        return reject(err);
      }
      resolve(result);
    });
  });

// Define the logout function
export const logout = () => {
  const user = userPool.getCurrentUser();
  if (user) {
    user.signOut();
  }
};

// Retrieve the current session for an authenticated user
export const getCurrentSession = () =>
  new Promise((resolve, reject) => {
    const user = userPool.getCurrentUser();
    if (!user) {
      return reject(new Error("No user is currently logged in."));
    }
    user.getSession((err, session) => {
      if (err) return reject(err);
      resolve(session);
    });
  });
