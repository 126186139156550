// src/ConfirmAccount.js
import React, { useState } from "react";
import { confirmSignUp, resendConfirmationCode } from "./services/cognitoService";
import { useNavigate } from "react-router-dom";

const ConfirmAccount = ({ email }) => {
  const [code, setCode] = useState("");
  const [message, setMessage] = useState("");
  const [resendMessage, setResendMessage] = useState("");
  const navigate = useNavigate(); // Initialize navigate for redirection

  const handleConfirm = async (e) => {
    e.preventDefault();
    try {
      // Confirm the user in Cognito
      await confirmSignUp(email, code);
      setMessage("Account confirmed successfully! Redirecting to your dashboard...");

      // Redirect to Landing Page after a short delay
      setTimeout(() => navigate("/landing"), 1500);
    } catch (error) {
      setMessage(error.response?.data || error.message || "Failed to confirm account. Please try again.");
    }
  };

  const handleResendCode = async () => {
    try {
      await resendConfirmationCode(email);
      setResendMessage("A new verification code has been sent to your email.");
    } catch (error) {
      setResendMessage(error.message || "Failed to resend verification code.");
    }
  };

  return (
    <div>
      <h2>Confirm Your Account</h2>
      <p>Please enter the verification code sent to your email to confirm your account.</p>
      <form onSubmit={handleConfirm}>
        <input
          type="text"
          name="code"
          placeholder="Verification Code"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
        />
        <button type="submit">Confirm Account</button>
      </form>
      <p>{message}</p>

      <button onClick={handleResendCode}>Resend Code</button>
      <p>{resendMessage}</p>
    </div>
  );
};

export default ConfirmAccount;
